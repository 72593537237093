import React from 'react';

// Legend:
//  param ---- desc  ----  what to expect
//---------------------------------------------
//  type ---- type of button for class ----- string
//  action ---- action for execute ---- function
//  disabled ---- if disabled or not ----- boolean
//  loader ---- active loader for button ---- boolean
//  additionalClass ---- used for adding a additional class as margin ---- string> css class
//  content ---- icons or text, for text sent only the text, and icons example with icon:
/* <span>
    <i className="nicon-play"></i>
    {this.showIosOptions() ? I18n.t('actions.alarm.try_ios') : I18n.t('actions.alarm.try')}
    </span>*/

// Full example
/*
    <ButtonNewUI
      type={'primary'}
      disabled={this.props.btnDisabled}
      action={(e) => this.props.handleSubmit(e)}
      content={I18n.t('content_button')}
      loader={this.props.loading}
    />
*/
const ButtonNewUI = ({type, action, content, disabled = false, loader = false, additionalClass = '', id = ''}) => {
  const classButton = (type) => {
    switch (type) {
      // -------- Medium/Default -----------
      case 'primary':
        return 'primary-btn';
      case 'secondary':
        return 'secondary-btn';
      case 'ghost':
        return 'ghost-btn';
      // --------- Small -----------------
      case 'primary-small':
        return 'button--small primary-btn--small';
      case 'secondary-small':
        return 'button--small secondary-btn--small';
      case 'tertiary-small':
        return 'button--small tertiary-btn--small';
      // ----------- Large -----------
      case 'primary-large':
        return 'button--large primary-btn--large';
      case 'secondary-large':
        return 'button--large secondary-btn--large';
      case 'tertiary-large':
        return 'button--large tertiary-btn--large';
      // ----------- Medium/Default -- Success -----------
      case 'success-primary':
        return 'primary-btn__success';
      case 'success-secondary':
        return 'secondary-btn__success';
      case 'tertiary--success':
        return 'tertiary-btn__success';
      // --------- Small -- Success -----------------
      case 'primary-small--success':
        return 'button--small primary-btn__success';
      case 'secondary-small--success':
        return 'button--small secondary-btn__success';
      case 'tertiary-small--success':
        return 'button--small tertiary-btn__success';
      // --------- Large -- Success -----------------
      case 'primary-large--success':
        return 'button--large primary-btn__success';
      case 'secondary-large--success':
        return 'button--large secondary-btn__success';
      case 'tertiary-large--success':
        return 'button--large tertiary-btn__success';
      // ----------- Medium/Default -- Error -----------
      case 'cancel-primary':
        return 'primary-btn__cancel';
      case 'cancel-secondary':
        return 'secondary-btn__cancel';
      case 'tertiary--cancel':
        return 'tertiary-btn__cancel';
      // ----------- Medium/Default -- Warning -----------
      case 'warning-primary':
        return 'primary-btn__warning';
      case 'warning-secondary':
        return 'secondary-btn__warning';
      case 'tertiary--warning':
        return 'tertiary-btn__warning';
      // --------- Small -- Error -----------------
      case 'primary-small--cancel':
        return 'button--small primary-btn__cancel';
      case 'secondary-small--cancel':
        return 'button--small secondary-btn__cancel';
      case 'tertiary-small--cancel':
        return 'button--small tertiary-btn__cancel';
      // --------- Large -- Error -----------------
      case 'primary-large--cancel':
        return 'button--large primary-btn__cancel';
      case 'secondary-large--cancel':
        return 'button--large secondary-btn__cancel';
      case 'tertiary-large--cancel':
        return 'button--large tertiary-btn__cancel';
      // -------- Medium/Default - Icons -----------
      case 'primary-icon':
        return 'primary-btn--icon';
      case 'secondary-icon':
        return 'secondary-btn--icon';
      case 'tertiary-icon':
        return 'tertiary-btn--icon';
      // =========>
      case 'fill-btn-error':
        return 'fill-btn-error';
      case 'large-btn':
        return 'large-btn';
      default:
        return 'primary-btn';
    }
  };

  return (
    <button
      className={`button ${classButton(type)} ${loader ? 'button__loader' : ''} ${additionalClass}`}
      onClick={action}
      disabled={disabled || false}
      id={id}
    >
      {loader && <i className="loader xsmall"></i>}
      {content}
    </button>
  );
};

export default ButtonNewUI;
