import serialize from 'form-serialize';
import history from '../thewatch/components/history';
import {isProduction} from '../thewatch/helpers/env';

const hotjarTrigger = (name) => {
  if (window.location.protocol === 'http:') return;

  if (typeof window.hj === 'undefined') return;

  window.hj =
    window.hj ||
    function () {
      (hj.q = hj.q || []).push(arguments);
    };

  hj('identify', window.analytics_key, {email: window.email});
  hj('trigger', name);
  Debug(`${name} triggered in hotjar`);
};

const isMdmAction = (operating_system, action_name) => {
  switch (operating_system) {
    case 'Ios':
      return ['wipe', 'lock', 'clearpasscode'].includes(action_name);
    case 'Mac':
      return ['fulllock', 'fullwipe'].includes(action_name);
    default:
      return false;
  }
};

function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const throttle = (func, limit) => {
  let engaged = false;
  return function () {
    const args = arguments;
    const context = this;
    if (!engaged) {
      func.apply(context, args);
      engaged = true;
      setTimeout(() => (engaged = false), limit);
    }
  };
};

const cookieExists = (name) => {
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
};

const parseSourceFromRoutingLocation = (props) => {
  if (props.location && props.location.state && props.location.state.source) {
    return props.location.state.source;
  }

  return null;
};

const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const disableEnterOnSubmit = (event) => {
  if (event.type == 'submit') {
    event.preventDefault();
  }
};

const serializeForm = (element, options) => {
  const values = serialize(element, options);
  if (values.options && values.options.close_apps) {
    values.options.close_apps = values.options.close_apps === 'true';
  }
  return values;
};

/**
 * Evaluates if given permissions is enabled for logged user
 * and also verify if it requires some feature included in plans
 *
 * @param {string} kind - A string param
 * @param {string} neededValue - A string param
 * @return {boolean}
 *
 * @example
 *
 *     verifyPermission('access', 'mass_actions)
 */
const verifyPermission = (kind, neededValue) => {
  let foundPermissionGroup =
    store.getState().account.current_user.available.permissions &&
    store.getState().account.current_user.available.permissions[kind];

  // check if user has the given kind (group -> access)
  if (!foundPermissionGroup) {
    return false;
  }

  // within the found group search by the neededValue (mass_action)
  let foundPermissionKind = foundPermissionGroup.find((o) => {
    return o.name === neededValue;
  });

  // verify if user has the permission
  if (!foundPermissionKind) {
    return false;
  }

  // check if permission dependes on some feature
  if (foundPermissionKind.requires) {
    return store.getState().account.current_user.available.features[foundPermissionKind.requires];
  } else {
    return true;
  }
};

const disableReactDevTools = () => {
  // Check if the React Developer Tools global hook exists
  if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ !== 'object') {
    return;
  }

  for (const prop in window.__REACT_DEVTOOLS_GLOBAL_HOOK__) {
    if (prop === 'renderers') {
      // initialise this with an empty `Map`,
      // else it will throw an error in console

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] = new Map();
    } else {
      // Replace all of its properties with a no-op function or a null value
      // depending on their types

      window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] =
        typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__[prop] === 'function' ? () => {} : null;
    }
  }
};

const validateFileSize = (file, maxSize = 5) => {
  const fileSize = (file?.size / 1024 / 1024)?.toFixed(2);
  if (fileSize != 'NaN') {
    return parseFloat(fileSize) < maxSize;
  }
  return false;
};

/**
 * Logs a message to the console if the app is not in production or if the 'showDebug' flag is set.
 *
 * @param {string} msg The message to log.
 * @param {string} [msg2] An optional second message to log after the first.
 */
const Debug = (msg, msg2) => {
  if (msg == undefined) return;
  if (msg2 === undefined) {
    msg2 = '';
  }

  if (!isProduction() || localStorage.getItem('showDebug')) {
    console.log(msg, msg2);
  }
};

/**
 * Remove the 'viewing-content' class from the first element found with the class, which is
 * the main content container, and push the given URL to the browser's history.
 *
 * @param {string} url - Optional. The URL to push to the browser's history.
 */
const backToAside = (url) => {
  document.getElementsByClassName('viewing-content')[0].classList.remove('viewing-content');
  if (url) {
    history.push(url);
  }
};

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export {
  parseSourceFromRoutingLocation,
  hotjarTrigger,
  isMdmAction,
  throttle,
  cookieExists,
  debounce,
  capitalize,
  disableEnterOnSubmit,
  serializeForm,
  verifyPermission,
  disableReactDevTools,
  validateFileSize,
  Debug,
  backToAside,
  emailRegex
};
